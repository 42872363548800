<template>
  <section id="dashboard-ecommerce">
    <div>
      <div>
        <b-card class="">
          <div class="mb-1 font-weight-bolder dash-coupon-list-title">
            Banners<span class="float-right text-blue cursor-pointer" @click="$eventBus.$emit('handleMediaType')"
              >Collapse<v-icon class="text-blue">mdi-chevron-left</v-icon></span
            >
          </div>
          <b-row class="match-height custom-media-row mt-2">
            <b-col
              xl="4"
              md="6"
              class="custom-media-col"
              v-for="(item, index) in mediaLogoData"
              :key="index"
            >
              <b-card class="custom-banner-card">
                <div class="truncate">
                  <h5 class="mb-25 font-weight-bolder">{{ item.name }}</h5>
                  <span class="font-10">#23543647</span>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      data: {},
      mediaLogoData: [
        { name: "Noon" },
        { name: "Sephora" },
        { name: "Noon" },
        { name: "Amazon" },
        { name: "Namshi" },
        { name: "H&M" },
        { name: "Noon" },
        { name: "Sephora" },
        { name: "Noon" },
        { name: "Amazon" },
        { name: "Namshi" },
        { name: "H&M" },
      ],
      popoverShow: false,
      popoverShow1: false,
      value: "1",
    };
  },
  created() {},
  methods: {
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    }, 
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.card {
  border-radius: 22px;
}
.custom-media-card {
  background: #f4f9fd;
  margin: 0px !important;
}
.custom-media-row {
  margin: 0px !important;
  .custom-media-col {
    padding: 8px !important;
  }
  .font-10 {
    font-size: 10px !important;
  }
}
.custom-banner-card {
  color: #fff !important;
  margin: 0px !important;
  height: 150px;
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  h5 {
    color: #fff;
  }
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
