<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">Media Hub{{ search }}</h1>
      <div>
        <button type="button" class="btn notofication-btn mr-1"  v-b-tooltip.hover.v-primary title="Export CSV">
          <img src="../../../assets/images/client/export.svg" alt="Snow" @click="exportToCSV" />
        </button>
<!-- 
        <button id="popover-button-variant" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1"
          @click="onPopovcerOpen()">
          <div>
            <img id="task1" src="../../../assets/images/client/task-square.svg" alt="Snow" />
          </div>
        </button>

        <b-popover ref="popover" target="popover-button-variant" triggers="click" class="custom-popover"
          :show.sync="popoverShow">
          <button @click="onPopovcerOpen" class="notofication-btn" style="color: #408dff; margin: 5px" size="sm">
            Cancel
          </button>

          <button type="button" class="btn notofication-btn" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn ml-1" style="background: #f4f9fd; margin: 8px">
            <img src="../../../assets/images/client/circle.svg" alt="Snow" />
          </button>
          <button type="button" class="btn notofication-btn"
            style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px">
            <img src="../../../assets/images/client/delete.png" alt="Snow" />
          </button>
        </b-popover> -->

        <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 16px"
          @click="addMediaHandelClick" v-b-modal.modal>
          +&nbsp; Add Media
        </button>
        <b-modal size="lg" id="modal" ref="modal" class="m-2 custom--model">
           <template #modal-header>
          <div style="width:50%;padding: 10px 20px;" class="form-row">
              <h2>{{edit ? 'Edit ' :'Add '}} Media</h2>
          </div>
            <b-button @click="closeModal" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Image </label>
              <small class="text-danger"> (Max size : 150 KB)</small>
              <input type="file" ref="image" @change="handelChangeFile($event)" class="form-control" placeholder="Enter Image " />
            </div>
            <div class="form-group col-md-6">
              <label>Name</label>
              <input type="text" v-model="name" class="form-control" placeholder="Enter Name" />
            </div>
            <div class="form-group col-md-6">
              <label>Type</label>
              <v-select  v-model="type" label="text" :options="[{'text':'Logo ( Max: 100 x 100 )','value':'Logo'},{'value':'Banner','text':'Banner ( Max:  925 x 450 )'}]" placeholder="Enter Type" :clearable="false"/>
            </div>
          </div>
          <div class="modal-footer1">
            <button type="button" class="btn btn-default mr-1"  @click="addMediaHubHandelClick('addanother')" style="border: 1px solid black" data-dismiss="modal">
              Add Another Media
            </button>
            <button type="button" @click="addMediaHubHandelClick" class="btn btn-primary"> {{edit ?'Update' :'Add'}}</button>
          </div>
        </b-modal>
      </div>
    </div>
    <div>
      <b-card class="" style="min-height: 5cm ;">
        <div class="mb-1 font-weight-bolder dash-coupon-list-title">
          Brand Logos<span class="float-right text-blue cursor-pointer"
            @click="handleBrandType(mediaLogoType == 'Expand' ? 'Collapse' : 'Expand','Logo')">{{ mediaLogoType }}<v-icon
              class="text-blue">{{ mediaLogoType == 'Collapse' ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon></span>
        </div>
          
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </div>
          <div class="d-flex justify-content-center align-items-center" v-else-if="mediahublogoData.length == 0">
            <img style="width:8cm; height:8cm" :src="host + '/NoDataFound.svg'" />
          </div>
        <b-row v-else class="match-height custom-media-row mt-2">
          <b-col xl="2" md="6" :id="'popover-button-variant' + item.id" href="#"
            class="custom-media-col"
            v-for="(item, index) in mediahublogoData.slice(0, mediaLogoType == 'Expand' ? 6 : mediahublogoData.length)" @dblclick="handelView(item.id)"
            :key="index">
            <b-popover ref="myPopover" placement="top" :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover"
              >
              <!-- <button @click="onPopovcerOpen3(item.id)" class="notofication-btn" style="color: #408dff; margin: 5px"
                size="sm">
                Cancel
              </button> -->

                      <button
                      @click="CopyLogo(item.id)"
                      variant="primary"
                      type="button"
                      class="btn notofication-btn"
                      style="background: #f4f9fd; margin: 8px 0px 8px 4px"
                    >
                      <img  src="../../../assets/images/client/copy.png" alt="Snow" />
                    </button>
                     <button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      :title="item.active ? 'Disable' : 'Enable'"
                      variant="outline-primary"
                      type="button"
                      @click="HandelClickEnableDisabled(item.id,item.active)"
                      class="btn notofication-btn"
                      style="background: #f4f9fd; margin: 8px 0px 8px 0px"
                    >
                      <img v-if="!item.active" src="../../../assets/images/client/circle.svg" alt="Snow" />
                      <img v-else src="../../../assets/images/client/circleDisabled.svg"  alt="Snow" />
                    </button>
                    <button type="button" class="btn notofication-btn" @click="handelView(item.id)"
                      style="background: rgb(244, 249, 253); margin: 8px 0px 8px 0px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                    </button>
                    <button type="button" class="btn notofication-btn" @click="handelEdit(item.id)"
                      style="background: rgb(244, 249, 253); margin: 8px 0px 8px 0px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"  class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
              <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)"
                style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px 4px 8px 0px">
                <img src="../../../assets/images/client/delete.png" alt="Snow" />
              </button>
            </b-popover>
            <b-modal size="md"  v-model="modalVisible[item.id]" style="display:flex" :title="item.name.split('.').slice(0, -1).join('.') ">
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>MediaHub</h2>
              </div>
            </div>
            <b-button @click="handelView(item.id)" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <div class="w-100 d-flex justify-content-center">
              <img
                    v-if="checkURL(item.image_url)"
                    style="width:450px;height: fit-content;"
                    :src="item.image_url" 
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <img
                    v-else
                    style="width:450px;height: fit-content;"
                    :src="require('@/assets/images/no-pic.png')"
                    alt="Circle image"
                    class="d-inline-block"
                  />
          </div>

            </b-modal> 
            <!-- <div 
            :style="popoverShow3[item.id] ? 'border: 3px solid #2992ff' : ''" style="border-radius: 25px;" class="text-center p-1 custom-media-card">  -->
            <div style="border-radius: 25px;" class="text-center p-1 custom-media-card">
              <button type="button" class="" style="width:100%; display:flex; justify-content: end;">
                <img v-if="item.active" src="../../../assets/images/client/circle.svg" alt="Snow" />
                <img v-else  src="../../../assets/images/client/circleDisabled.svg" alt="Snow" />
              </button>
              <b-avatar class="mb-1" variant="light" size="55">
                <div class="ml-1 mr-2">
                  <!-- <img src="../../../assets/images/client/photo.png" /> -->
                  
                  <b-img
                    v-if="checkURL(item.image_url)"
                    v-bind="mainProps"
                    rounded="circle"
                    :src="item.image_url" 
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <b-img
                    v-else
                    v-bind="mainProps"
                    rounded="circle"
                    :src="require('@/assets/images/no-pic.png')"
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <!-- <img :src="item.image_url" /> -->
                </div>
              </b-avatar>
              <div class="truncate">
                <h5 class="mb-30 text-black" style="font-weight: 800;">{{ item.name }}</h5>
                <span class="font-10" style="font-weight: 600;">#{{ item.size }}</span>
              </div>
              <div class="mt-50 font-10" style="font-weight: 600;">Upload date {{ moment(item.updated_at).format('MM/DD/YYYY') }}</div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="" style="min-height: 5cm ;">
        <div class="mb-1 font-weight-bolder dash-coupon-list-title">
          Brand Banners<span class="float-right text-blue cursor-pointer"
            @click="handleBrandType(mediaBannerType == 'Expand' ? 'Collapse' : 'Expand','Banner')">{{ mediaBannerType }}<v-icon
              class="text-blue">{{ mediaBannerType == 'Collapse' ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon></span>
        </div>
          
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
          </div>
          <div class="d-flex justify-content-center align-items-center" v-else-if="mediahubbannerData.length == 0">
            <img style="width:8cm; height:8cm" :src="host + '/NoDataFound.svg'" />
          </div>
          <b-row v-else class="match-height custom-media-row mt-2">
         <b-col xl="4" md="6" :id="'popover-button-variant' + item.id" href="#"
            class="custom-media-col"
            v-for="(item, index) in mediahubbannerData.slice(0, mediaBannerType == 'Expand' ? 3 : mediahubbannerData.length)" @dblclick="handelView(item.id)"
            :key="index">
            <b-popover placement="top" ref="myPopover" :target="'popover-button-variant' + item.id" triggers="hover" class="custom-popover">
                      <button
                      @click="CopyLogo(item.id)"
                      variant="primary"
                      type="button"
                      class="btn notofication-btn"
                      style="background: #f4f9fd; margin: 8px 0px 8px 4px"
                    >
                      <img  src="../../../assets/images/client/copy.png" alt="Snow" />
                    </button>
                     <button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      :title="item.active ? 'Disable' : 'Enable'"
                      variant="outline-primary"
                      type="button"
                      @click="HandelClickEnableDisabled(item.id,item.active)"
                      class="btn notofication-btn"
                      style="background: #f4f9fd; margin: 8px 0px 8px 0px"
                    >
                      <img v-if="!item.active" src="../../../assets/images/client/circle.svg" alt="Snow" />
                      <img v-else src="../../../assets/images/client/circleDisabled.svg"  alt="Snow" />
                    </button>
                    <button type="button" class="btn notofication-btn" @click="handelView(item.id)"
                      style="background: rgb(244, 249, 253); margin: 8px 0px 8px 0px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                    </button>
                    <button type="button" class="btn notofication-btn" @click="handelEdit(item.id)"
                      style="background: rgb(244, 249, 253); margin: 8px 0px 8px 0px">
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"  class="bi bi-pencil-square"
                        viewBox="0 0 16 16">
                        <path
                          d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
              <button type="button" class="btn notofication-btn" @click="handelDelete(item.id)"
                style="background-color: rgba(246, 81, 96, 0.135735); margin: 8px 4px 8px 0px">
                <img src="../../../assets/images/client/delete.png" alt="Snow" />
              </button>
            </b-popover>
            <b-modal v-model="modalVisible[item.id]" style="display:flex" :title="item.name.split('.').slice(0, -1).join('.') ">
             
          <template #modal-header>
            <div style="width:80%;padding: 10px 20px;" class="form-row">
              <div class="form-group col-md-12">
                <h2>MediaHub</h2>
              </div>
            </div>
            <b-button @click="handelView(item.id)" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <div class="d-flex justify-content-center w-100">

              <img
                    v-if="checkURL(item.image_url)"
                    style="width:450px;height: fit-content;"
                    :src="item.image_url" 
                    alt="Circle image"
                    class="d-inline-block"
                  />
                  <img
                    v-else
                    style="width:450px;height: fit-content;"
                    :src="require('@/assets/images/no-pic.png')"
                    alt="Circle image"
                    class="d-inline-block"
                  />
          </div>

            </b-modal> 
              <b-card  style="border-radius: 25px;" class="custom-banner-card" :style="`background: linear-gradient(0deg, #000000 0%, rgba(196, 196, 196, 0) 100%),url('${item.image_url}');`">
              <!-- <b-card  style="border-radius: 25px;" class="custom-banner-card" > -->
                
              <button type="button" class="" style="width:100%; display:flex; justify-content: end;">
                <img v-if="item.active" src="../../../assets/images/client/circle.svg" alt="Snow" />
                <img v-else  src="../../../assets/images/client/circleDisabled.svg" alt="Snow" />
              </button>
                <div class="truncate">
                  <h5 class="mb-25 font-weight-bolder">{{ item.name.split('.').slice(0, -1).join('.') }}</h5>
                  <span class="font-10  text-black">#{{ item.size}}</span>
                </div>
              </b-card>
            </b-col>
          </b-row>
        <!-- </b-row> -->
      </b-card>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import BrandLogo from "./Brand-logo.vue";
import moment from 'moment';
import Banner from "./Banner.vue";
import json2csv from 'json2csv';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from "vue-select";
export default {
  components: {
    draggable,
    ToastificationContent,
    BrandLogo,
    VSelect,
    Banner,
  },
  data() {
    return {
      moment,
      data: {},
      mainProps: {
      width: 50,
      height: 50,
      class: 'm1',
      },
     modalVisible: [],
      mediaLogoType: "Expand",
      mediaBannerType: "Expand",
      image_url: '',
      size: '',
      name:'',
      type: {'text':'Logo ( Max: 100 x 100 )','value':'Logo'},
      isLoading:false,
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      popoverShow: false,
      mediahublogoData: [],
      mediahubbannerData: [],
      mediahublogoData1: [],
      mediahubbannerData1 : [],
      value: "1",
      edit: false
    };
  },
  created() { },
  mounted() {
    this.$bus.$on('globalScroll', this.applyScrollEffect);
    this.getMediahub()
    // this.$eventBus.$on("handleMediaType", (data) => {
    //   console.log(data)
    //   this.mediaType = "both";
    // });
  },
  computed: {
    search() {
      this.isLoading=true;

      let search = this.$store.state.searchData
      if(this.$store.state.application){
        this.getSearch(search);
      }
    },
  },
  beforeDestroy() {
    this.$bus.$off('globalScroll', this.applyScrollEffect);
  },  
  methods: {
    applyScrollEffect(event) {
      console.log('Scroll event received:',this.$refs['myPopover'], event);
    },
     getSearch: _.debounce(function (search) {
      this.$http.post(`${this.baseURL}/mediahub/search`, {search: search},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.isLoading=false;
          this.mediahublogoData = []
        this.mediahublogoData1 = []
        this.mediahubbannerData = []
        this.mediahubbannerData1 = []
        res.data.data.map(item => {
          if (item.type == 'Logo') {
            this.mediahublogoData.push(item)
            this.mediahublogoData1.push(item)
          }
          if (item.type == 'Banner') {
            this.mediahubbannerData.push(item)
            this.mediahubbannerData1.push(item)
          }
        })
        })
      // }
    }, 1000),
    
    exportToCSV() {
       let array = []
       this.mediahublogoData.map(item => {
         let data = {
            Type : item.type,
            Name : item.name,
            'Image Url' : item.image_url,
         }
         array.push(data);
       })

       this.mediahubbannerData.map(item => {
         let data = {
            Type : item.type,
            Name : item.name,
            'Image Url' : item.image_url,
         }
         array.push(data);
       })
      const csvData = json2csv.parse(array);
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'MediaHub.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handelView(id){
      this.modalVisible[id] = this.modalVisible[id] == undefined ? false : this.modalVisible[id];
      this.modalVisible[id] = this.modalVisible[id] == true ? false : true;
      this.popoverShow= false,
     this.$refs['modal'].hide();
     this.$forceUpdate()
    },
    CopyLogo(id){
      this.$http.post(`${this.baseURL}/mediahub/copy-logo`, {id:id},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          this.$swal({  
                title: "Success",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                    ? `${res.data.success}`
                    : "Success",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getMediahub()
            this.popoverShow= false
              this.checkBoxIds = {}
              this.$forceUpdate()
        })
    },
  HandelClickEnableDisabled(id,active){
  console.log(id,active)
  active = active == 1 ? 0 : 1
  this.$http.post(`${this.baseURL}/mediahub/statuschange`, {id:id,active:active},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.getMediahub()
          })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copied',
          icon: 'CheckIcon',
        },
      })
    },
    onError() {
      this.$toast({ 
        component: ToastificationContent,
        props: {
          title: 'Failed to copy Link!',
          icon: 'XIcon',
        },
      })
    },
    checkURL(url) {
      const pattern = /^(ftp|http|https):\/\/[^"]+$/;
      return pattern.test(url);
    },
    handelChangeFile(e) {
      this.image_url = e.target.files[0]
    },
   async addMediaHubHandelClick(value) {
      const formData = new FormData();
      // if(!this.checkURL(this.image_url)){

        // formData.append('image_url', this.image_url);
      // }else{
        let imageDimensions=null;
        if(typeof this.image_url !== 'string'){

        const photoUrl = URL.createObjectURL(this.image_url);
        const image = new Image();
         imageDimensions = await new Promise((resolve) => {
            image.onload = () => {
              const dimensions = {
                height: image.height,
                width: image.width,
              };
              resolve(dimensions);
            };
          image.src = photoUrl;
          });
        }
      if(this.type.value == 'Logo' && imageDimensions && ( imageDimensions.width >100 || imageDimensions.height > 100)){
              this.$swal({
                title: "Error!!",
                text: 'Image size should be 100 x 100',
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              return
      }
      if (this.type.value == 'Banner' && imageDimensions &&( imageDimensions.width >925 || imageDimensions.height > 450)){
            this.$swal({
                title: "Error!!",
                text: 'Image size should be 925 x 450',
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              return
      }
      if( imageDimensions && this.image_url.size > 150000 ){
        this.$swal({
                title: "Error!!",
                text: 'Image size should be less then 150 KB',
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              return
      }
      if( !this.name ){
        this.$swal({
                title: "Warning",
                text: 'Name Field is Require',
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
              return
      }
      // }
      formData.append('size', this.size);
      formData.append('type', this.type.value);
      formData.append('image_url', this.image_url);
      formData.append('name', this.name);

      formData.append('app_id', this.$store.state.application.id);
      // let data = {
      //   image_url: this.image_url,
      //   size: this.size,
      //   type: this.type
      // }
      // console.log(data)
      if (this.edit) {
        formData.append('is_url', this.checkURL(this.image_url));
        formData.append('id', this.editItem.id);
      }
      this.$http.post(`${this.baseURL}/mediahub/${this.edit ? 'update' : 'create'}`, formData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
        
          if (json.data.status == 'error') {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.$swal({
                  title: "Submitted",
                  text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                    icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getMediahub()
              if(value == 'addanother'){
                this.popoverShow= false,
                this.edit = false
                this.image_url = ''
                this.name = ''
                this.size = ''
                this.type = {'text':'Logo ( Max: 100 x 100 )','value':'Logo'}
                this.$refs.image.value = ''
              }
              else{

                this.$refs['modal'].hide()
               
            }
            }
        })
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    handleBrandType(value,type) {
      if(type == 'Logo'){
        this.mediaLogoType = value;
      }
      else if(type == 'Banner'){
        this.mediaBannerType = value;
      }
    },
    async addMediaHandelClick() {
      this.popoverShow= false,
      this.edit = false
      this.image_url = ''
      this.name = ''
      this.size = ''
      this.type ={'text':'Logo ( Max: 100 x 100 )','value':'Logo'}
      this.$refs.image.value = ''
    },
    
    handelDelete(id){
      this.$http.post(`${this.baseURL}/mediahub/delete/${id}`).then((res)=>{
              this.$swal({
                title: "Deleted",
                text: res.data.message
                  ? `${res.data.message}`
                  : res.data.success
                  ? `${res.data.success}`
                  : "Deleted",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
      this.getMediahub()
      this.checkBoxIds = {}
      this.$forceUpdate()
            })
    },
    handelEdit(id){
      this.edit = true
      this.$refs['modal'].show()
      this.popoverShow=false
      this.$http.post(`${this.baseURL}/mediahub/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.$refs.image.value = ''
          this.image_url =this.editItem.image_url
          this.size = this.editItem.size
          this.name = this.editItem.name
          this.type = this.editItem.type == 'Logo'?{'text':'Logo ( Max: 100 x 100 )','value':'Logo'}:{'value':'Banner','text':'Banner ( Max:  925 x 450 )'}
          // this.getMediahub()
          })
    },
    closeModal(){
        this.$refs['modal'].hide()
    },
    getMediahub() {
      this.isLoading=true;
      this.$http.post(`${this.baseURL}/mediahub/index`).then((res) => {
        this.mediahublogoData = []
        this.mediahublogoData1 = []
        this.mediahubbannerData = []
        this.mediahubbannerData1 = []
        res.data.data.map(item => {
          if (item.type == 'Logo') {
            this.mediahublogoData.push(item)
            this.mediahublogoData1.push(item)
          }
          if (item.type == 'Banner') {
            this.mediahubbannerData.push(item)
            this.mediahubbannerData1.push(item)
          }
        })
          this.isLoading=false;
      })

    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.card {
  border-radius: 22px;
}

.custom-media-card {
  background: #f4f9fd;
  margin: 0px !important;
}

.custom-media-row {
  margin: 0px !important;

  .custom-media-col {
    padding: 8px !important;
  }

  .font-10 {
    font-size: 11px !important;
  }
}

.custom-banner-card {
  color: #fff !important;
  margin: 0px !important;
  height: 150px;

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h5 {
    color: #fff;
  }
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15 px;
  margin: 0;
  width: 200px;
  float: right;
}
.truncate-text {
  white-space: nowrap;     /* Prevent text from wrapping to the next line */
  overflow: hidden;        /* Hide any overflowing text */
  text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-top {
  border-radius: 14px !important;
  border: transparent;
  min-width: 320px !important;
  max-width: 400px !important;
}
.bs-popover-bottom {
  border-radius: 14px !important;
  border: transparent;
  min-width: 320px !important;
  max-width: 400px !important;
}
.bs-popover-top .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  // max-width: 550px !important; 
}

.modal-body {
   padding: 0px 40px 40px 40px !important
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
}

textarea.form-control {
  min-height: 150px !important;
}



.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
</style>
